import React, { useRef, useEffect } from "react";

function useOutsideAlerter(ref: any, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function ({link, setLink}: any) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, ()=> setLink());

  return (
    <div ref={wrapperRef} style={{display: link ? "block": "none"}} className={`instruction-modal modal fade ${link ? "show" : ""}`} id="video-intruction-modal" role="dialog" aria-labelledby="video-intruction-modal-title" aria-hidden={link ? "false" : "true"}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div id='videoPlayerModal'>
              <iframe 
                style={{position: "fixed", top: '20vh', left: '30vw'}} id="player" width="640" height="360"
                src={`https://www.youtube.com/embed/${link}/?enablejsapi=1&origin=https://seobuster.ru`}
                frameBorder="0"
              />
            </div>
        </div>
      </div>
    </div>
  )
}